<template>
  <v-container id="spider-tables" fluid tag="section">
    <v-col class="text-right">
      <v-dialog v-model="dialog_form" max-width="600px" @click:outside="close">
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
            @click="createItem"
          >
            <v-icon left>mdi-account-plus</v-icon>
            {{ $t("spider-create") }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.spider.name"
                    :label="$t('name')"
                    :disabled="editedIndex > -1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions v-if="action != 'view'">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <base-material-card
      icon="mdi-clipboard-text"
      title="Spider Table"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
      >
        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.status="{ item }">{{
          item.status | getText(spider_status)
        }}</template>
        <template v-slot:item.is_active="{ item }">
          <v-switch
            v-model="item.is_active"
            @change="updateActive(item.id, item.is_active)"
          ></v-switch>
        </template>
        <!-- status -->
        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(spider_status_array) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="spider_status_array"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="ping(item)"
                >mdi-new-box</v-icon
              >
            </template>
            <span>{{ $t("ping") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { spider_status_enum, spider_status_array } from "@/definition.js";
import SpiderProcess from "./SpiderProcess";
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    spider_status_enum: spider_status_enum,
    spider_status_array: spider_status_array,
    editedItem: {
      spider: {
        id: "",
        uuid: "",
        name: "",
        car: "",
        lbtype: "L",
        status: 0,
        is_active: false,
      },
    },
    defaultItem: {
      id: "",
      uuid: "",
      name: "",
      car: "",
      lbtype: "L",
      status: 0,
      is_active: false,
    },
    editedIndex: -1,
    action: "",
    dialog_form: false,
    items: [],
    totalItems: 0,
    spider_process: [],
    spider_process_count: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("name"),
        value: "name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("uuid"),
        value: "uuid",
      },

      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("is_active"),
        value: "is_active",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    spider_status: [
      {
        text: "Unknown",
        value: 0,
      },
      {
        text: "Online",
        value: 1,
      },
      {
        text: "Suspend",
        value: 2,
      },
    ],
  }),
  components: {
    SpiderProcess,
  },
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("spider");
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  methods: {
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/spiders/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    postApi() {
      // create hn account
      console.log("create spider");
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/spiders/";
      console.log("url " + url);
      let jsonData = {
        name: this.editedItem.spider.name,
        status: 0,
        is_active: true,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      // console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createItem() {
      this.action = "create";
    },
    close() {
      console.log("close me.");
      this.dialog_form = false;
      setTimeout(() => {
        this.editedItem.spider = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.patchApi(this.editedIndex);
        this.close();
      } else {
        this.postApi();
        this.close();
      }
      console.log("save");
    },
    updateActive(id, is_active) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/spiders/" + id + "/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      var jsonData = {};
      jsonData["is_active"] = is_active;
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
        });
    },
    updateStatus(item) {
      console.log("update spider status ", item);
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/spiders/" + item.id + "/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      var jsonData = {};
      jsonData["status"] = item.status;
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
        });
    },
    ping(item) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/system/spiders/${item.id}/ping/`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      var jsonData = {};
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
        });
    },
  },
};
</script>
