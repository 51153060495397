<template>
  <v-container id="spider-detail" fluid tag="section">
    <v-row>
      <v-col cols="2">
        <v-btn color="primary" @click="createDetail(item_id)">
          <v-icon left>mdi-new-box</v-icon>
          {{ $t("new") }}
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn color="secondary" @click="getStatus(item_id)">
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("refresh") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_form" max-width="600px" @click:outside="close">
      <v-card>
        <v-card-title>
          <span class="headline">Create Spider Detail</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :value="name"
                  :label="$t('name')"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :value="uuid" :label="$t('uuid')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.car"
                  :label="$t('car')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.lbtype"
                  :label="$t('status')"
                  :items="lbtyes"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="action != 'view'">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="postCreateDetail">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          multi-sort
          hide-default-footer
        >
          <template v-slot:item.lbtype="{ item }">{{
            item.lbtype | getText(lbtyes)
          }}</template>
          <template v-slot:item.create_time="{ item }">{{
            item.create_time | moment("YYYY-MM-DD HH:mm:ss")
          }}</template>
          <template v-slot:item.update_time="{ item }">{{
            item.update_time | moment("YYYY-MM-DD HH:mm:ss")
          }}</template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" v-on="on" @click="viewProcess(item)"
                  >mdi-eye-outline</v-icon
                >
              </template>
              <span>{{ $t("view") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" v-on="on" @click="stopProcess(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>{{ $t("view") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    action: "",
    dialog_form: false,
    editedItem: {
      car: "",
      lbtype: "L",
    },
    defaultItem: {
      car: "",
      lbtype: "L",
    },
    editedIndex: -1,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("pid"),
        value: "pid",
      },
      {
        sortable: false,
        text: vm.$i18n.t("lbtype"),
        value: "lbtype",
      },
      {
        sortable: false,
        text: vm.$i18n.t("car"),
        value: "car",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("login"),
        value: "login",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action"),
        value: "action",
      },
    ],
    lbtyes: [
      {
        text: "land",
        value: "L",
      },
      {
        text: "building",
        value: "B",
      },
      {
        text: "all",
        value: "A",
      },
    ],
  }),
  computed: {
    ...mapState(["token"]),
  },
  props: ["item_id", "name", "uuid"],
  methods: {
    setResult(data) {
      console.log(data);
      let info = JSON.parse(JSON.stringify(data));

      this.totalItems = data.count;
      this.items = data.results;
    },
    getAll(id) {
      this.loading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/spiders/" + id + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    getStatus(id) {
      console.log("Get status " + id);
      this.loading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/spiders/" + id + "/status/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    receiveSocketData(message) {
      console.log("Receive data: 2" + message.data);
      let info = JSON.parse(message.data);
      let cmd = info["cmd"];

      if (cmd == 2) {
        console.log("cmd " + cmd);
        this.getAll(this.id);
      }
    },
    stopProcess(item) {
      console.log("stop the item");
      console.log(item);
      // deleteNewSpiderProcess
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/spider_process/" +
        item.id +
        "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .delete(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getAll(currentObj.id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    viewProcess(item) {},
    createDetail(item) {
      console.log(item);
      this.editedIndex = item.id;

      this.dialog_form = true;
    },

    close() {
      // console.log("close me.");
      this.dialog_form = false;
      setTimeout(() => {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1;
      }, 300);
    },
    postCreateDetail() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/spider_process/";
      let jsonData = {
        car: this.editedItem.car,
        lbtype: this.editedItem.lbtype,
        spider: this.id,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          // currentObj.getAll(currentObj.id);
          currentObj.close();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
      // .finally(() => {
      //   this.close();
      // });
    },
  },
  mounted() {
    // this.$options.sockets.onmessage = this.receiveSocketData;
    this.id = this.item_id;
    this.name = this.name;
    this.uuid = this.uuid;
    console.log(
      "mounted id:" + this.id + " name:" + this.name + " uuid:" + this.uuid
    );
    this.getAll(this.id);
    this.getStatus(this.id);
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  },
};
</script>
